<template>
    <section
      id="chart-image"
    >
    <v-img
      :src="require('@/assets/chart-image.jpg')"
      height="700"
      contain=true
    >
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionChartImage',
  }
</script>
